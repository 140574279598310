/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  AncillaryIntervalDataAssetIntervalData,
  GetApiOptimizerLatestAncillariesParams,
  ObjectGenerateResponse,
  OperationType,
  PostApiOptimizerGenerateTypeTargetDateParams,
  SubmissionRequest,
  SubmissionType,
  TargetDate
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class OptimizerService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * @summary Generate requested submission type and immediately submit it into Tenaska
 */
 postApiOptimizerRoundtripTypeTargetDate<TData = void>(
    type: SubmissionType,
    targetDate: TargetDate, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/optimizer/roundtrip/${type}/${targetDate}`,undefined,options
    );
  }
/**
 * Generate: Creates a new AS offer for tomorrow but does not submit.
Submit: Locates the last non-submit AI AS offer for tomorrow and submit's it, returns 500 error if not found.
 * @summary Either generates or submits the AS submission for the next market day based on OperationMode
 */
 postApiOptimizerScheduledAncillaryOperation<TData = ObjectGenerateResponse>(
    operation: OperationType, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/optimizer/scheduled/ancillary/${operation}`,undefined,options
    );
  }
/**
 * @summary Get the latest ancillary transaction stated date (regardless of whether submit).
 */
 getApiOptimizerLatestAncillaries<TData = AncillaryIntervalDataAssetIntervalData[]>(
    params?: GetApiOptimizerLatestAncillariesParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/optimizer/latest/ancillaries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Make request to optimizer for DA ancillary offers for tomorrow, save in Cosmos and return the object.
 */
 postApiOptimizerGenerateTypeTargetDate<TData = ObjectGenerateResponse>(
    type: SubmissionType,
    targetDate: TargetDate,
    params?: PostApiOptimizerGenerateTypeTargetDateParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/optimizer/generate/${type}/${targetDate}`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Submit the AI transaction referenced in the URL to Tenaska, update the submission object and return.
 */
 postApiOptimizerSubmit<TData = ObjectGenerateResponse>(
    submissionRequest: SubmissionRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/optimizer/submit`,
      submissionRequest,options
    );
  }
};

export type PostApiOptimizerRoundtripTypeTargetDateClientResult = NonNullable<void>
export type PostApiOptimizerScheduledAncillaryOperationClientResult = NonNullable<ObjectGenerateResponse>
export type GetApiOptimizerLatestAncillariesClientResult = NonNullable<AncillaryIntervalDataAssetIntervalData[]>
export type PostApiOptimizerGenerateTypeTargetDateClientResult = NonNullable<ObjectGenerateResponse>
export type PostApiOptimizerSubmitClientResult = NonNullable<ObjectGenerateResponse>
