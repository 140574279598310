
export { QueryAsset, QueryTarget } from "./orval/schemas";

export enum QueryState {
    // Query has not yet been made (suppress errors on empty sets)
    Inactive,
    // Query being made (show progress bar)
    Loading,
    // Query has been made (can show errors if results empty)
    Complete
  }

