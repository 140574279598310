import { KeyOfType } from "@app/utils";
import { AncillaryIntervalData, PriceVolume } from "@models/api/orval/schemas";
import { QueryAsset, QueryTarget } from "@models/api/queries";

export type ResourceName =
  'NOBLESLR_BESS1' | 'NOBLESLR_BESS2' | 'NOBLESLR_LD1' | 'NOBLESLR_LD2' |
  'CORALSLR_BESS1' | 'CORALSLR_BESS2' | 'CORALSLR_LD1' | 'CORALSLR_LD2';

const n = (ld:boolean,no:number) => `NOBLESLR_${ld?'LD':'BESS'}${no}`;
const c = (ld:boolean,no:number) => `CORALSLR_${ld?'LD':'BESS'}${no}`;

const f : Record<QueryAsset, [Function,number]> = {
  [QueryAsset.NOBLE1]: [n,1],
  [QueryAsset.NOBLE2]: [n,2],
  [QueryAsset.CORAL1]: [c,1],
  [QueryAsset.CORAL2]: [c,2]
}

const allResources = [
  QueryAsset.NOBLE1,
  QueryAsset.NOBLE2,
  QueryAsset.CORAL1,
  QueryAsset.CORAL2
];

/**
 * Get a collection of all query targets (load and gen side of all resources)
 * Expand this function with filters in the future to get only load, noble, etc.
 */
export function getAllQueryTargets():QueryTarget[]{
  const isLoad = [true,false];
  return allResources.flatMap( r => isLoad.map( l => ({isLoad:l, name: r})));
}

/**
 * Get the unit name of the provided asset (i.e. NOBLESLR_BESS1)
 */
export function getResourceName(asset:QueryTarget): ResourceName {
  let [fmt,no] = f[asset.name];
  return fmt(asset.isLoad, no);
}

/**
 * Rendering and input precision for price and volume
 */
export const precision = {
  price : 2,
  volume : 1
}

/**
 * Timezone used for rendering dates and for storing dates
 */
export const uiTimezone = 'America/Chicago';

/**
 * Despite using Moment the Chart.JS plugin doesn't understand the timezone suffix in our normal date format
 */
export const dateTimeFormatCharts = 'M/D/YY HH:mm (CT)';

/**
 * Definition of ancillary field labels and data key names for use throughout app
 */
export const ancillaryFields : 
{
  key: KeyOfType<Required<AncillaryIntervalData>, PriceVolume>
  label: string
}[]  = [
  { key: 'rrs', label: 'RRS' },
  { key: 'regUp', label: 'Reg Up' },
  { key: 'regDown', label: 'Reg Down' },
  { key: 'ecrs', label: 'ECRS' },
  { key: 'nonSpin', label: 'Non Spin' }
]