/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  AncillaryOverride,
  AncillarySubmissionDocument,
  CopOverride,
  CopSubmissionDocument,
  EnergySubmissionDocument,
  GetApiTransactionsAncillariesParams,
  GetApiTransactionsCopParams,
  GetApiTransactionsLatestGeneratedAncillariesParams,
  GetApiTransactionsLatestGeneratedCopParams,
  GetApiTransactionsLatestGeneratedRealtimeParams,
  GetApiTransactionsRealtimeParams,
  ObjectIAsyncEnumerable,
  RealtimeOverride
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class TransactionsService {
  constructor(
    private http: HttpClient,
  ) {} getApiTransactionsAncillaries<TData = AncillarySubmissionDocument[]>(
    params: GetApiTransactionsAncillariesParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/ancillaries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiTransactionsRealtime<TData = EnergySubmissionDocument[]>(
    params: GetApiTransactionsRealtimeParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/realtime`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiTransactionsCop<TData = CopSubmissionDocument[]>(
    params: GetApiTransactionsCopParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/cop`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiTransactionsLatestGeneratedAncillaries<TData = AncillarySubmissionDocument>(
    params?: GetApiTransactionsLatestGeneratedAncillariesParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/latest/generated/ancillaries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiTransactionsLatestGeneratedRealtime<TData = EnergySubmissionDocument>(
    params?: GetApiTransactionsLatestGeneratedRealtimeParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/latest/generated/realtime`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiTransactionsLatestGeneratedCop<TData = CopSubmissionDocument>(
    params?: GetApiTransactionsLatestGeneratedCopParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/latest/generated/cop`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Delete the override with the specified ID
 */
 deleteApiTransactionsOverrideId<TData = void>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `/api/transactions/override/${id}`,options
    );
  }
/**
 * @summary Overwrite the ancillary override (if it exists) for the day in question with the information provided.
 */
 postApiTransactionsOverridesAncillaries<TData = AncillaryOverride>(
    ancillaryOverride: AncillaryOverride, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/transactions/overrides/ancillaries`,
      ancillaryOverride,options
    );
  }
 postApiTransactionsOverridesCop<TData = CopOverride>(
    copOverride: CopOverride, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/transactions/overrides/cop`,
      copOverride,options
    );
  }
 postApiTransactionsOverridesRealtime<TData = RealtimeOverride>(
    realtimeOverride: RealtimeOverride, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/transactions/overrides/realtime`,
      realtimeOverride,options
    );
  }
/**
 * @summary Fetch overrides for today and all future dates
 */
 getApiTransactionsOverridesFuture<TData = ObjectIAsyncEnumerable>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/transactions/overrides/future`,options
    );
  }
};

export type GetApiTransactionsAncillariesClientResult = NonNullable<AncillarySubmissionDocument[]>
export type GetApiTransactionsRealtimeClientResult = NonNullable<EnergySubmissionDocument[]>
export type GetApiTransactionsCopClientResult = NonNullable<CopSubmissionDocument[]>
export type GetApiTransactionsLatestGeneratedAncillariesClientResult = NonNullable<AncillarySubmissionDocument>
export type GetApiTransactionsLatestGeneratedRealtimeClientResult = NonNullable<EnergySubmissionDocument>
export type GetApiTransactionsLatestGeneratedCopClientResult = NonNullable<CopSubmissionDocument>
export type DeleteApiTransactionsOverrideIdClientResult = NonNullable<void>
export type PostApiTransactionsOverridesAncillariesClientResult = NonNullable<AncillaryOverride>
export type PostApiTransactionsOverridesCopClientResult = NonNullable<CopOverride>
export type PostApiTransactionsOverridesRealtimeClientResult = NonNullable<RealtimeOverride>
export type GetApiTransactionsOverridesFutureClientResult = NonNullable<ObjectIAsyncEnumerable>
