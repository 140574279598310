// src/index.ts
export * from "@tanstack/query-core";

// src/query-options.ts
function queryOptions(options) {
  return options;
}

// src/infinite-query-options.ts
function infiniteQueryOptions(options) {
  return options;
}

// src/inject-infinite-query.ts
import { InfiniteQueryObserver } from "@tanstack/query-core";

// src/create-base-query.ts
import { DestroyRef, Injector, NgZone, computed as computed2, effect, inject, runInInjectionContext, signal, untracked as untracked3 } from "@angular/core";
import { QueryClient, notifyManager } from "@tanstack/query-core";

// src/signal-proxy.ts
import { computed, untracked } from "@angular/core";
function signalProxy(inputSignal) {
  const internalState = {};
  return new Proxy(internalState, {
    get(target, prop) {
      const computedField = target[prop];
      if (computedField) return computedField;
      const targetField = untracked(inputSignal)[prop];
      if (typeof targetField === "function") return targetField;
      return target[prop] = computed(() => inputSignal()[prop]);
    },
    has(_, prop) {
      return !!untracked(inputSignal)[prop];
    },
    ownKeys() {
      return Reflect.ownKeys(untracked(inputSignal));
    },
    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true
      };
    }
  });
}

// src/util/index.ts
function shouldThrowError(throwError, params) {
  if (typeof throwError === "function") {
    return throwError(...params);
  }
  return !!throwError;
}
function noop() {}

// src/util/lazy-init/lazy-init.ts
import { untracked as untracked2 } from "@angular/core";
function lazyInit(initializer) {
  let object = null;
  const initializeObject = () => {
    if (!object) {
      object = untracked2(() => initializer());
    }
  };
  queueMicrotask(() => initializeObject());
  return new Proxy({}, {
    get(_, prop, receiver) {
      initializeObject();
      return Reflect.get(object, prop, receiver);
    },
    has(_, prop) {
      initializeObject();
      return Reflect.has(object, prop);
    },
    ownKeys() {
      initializeObject();
      return Reflect.ownKeys(object);
    },
    getOwnPropertyDescriptor() {
      return {
        enumerable: true,
        configurable: true
      };
    }
  });
}

// src/create-base-query.ts
function createBaseQuery(optionsFn, Observer) {
  const injector = inject(Injector);
  return lazyInit(() => {
    const ngZone = injector.get(NgZone);
    const destroyRef = injector.get(DestroyRef);
    const queryClient = injector.get(QueryClient);
    const defaultedOptionsSignal = computed2(() => {
      const options = runInInjectionContext(injector, () => optionsFn(queryClient));
      const defaultedOptions = queryClient.defaultQueryOptions(options);
      defaultedOptions._optimisticResults = "optimistic";
      return defaultedOptions;
    });
    const observer = new Observer(queryClient, defaultedOptionsSignal());
    const resultSignal = signal(observer.getOptimisticResult(defaultedOptionsSignal()));
    effect(() => {
      const defaultedOptions = defaultedOptionsSignal();
      observer.setOptions(defaultedOptions, {
        // Do not notify on updates because of changes in the options because
        // these changes should already be reflected in the optimistic result.
        listeners: false
      });
      untracked3(() => {
        resultSignal.set(observer.getOptimisticResult(defaultedOptions));
      });
    }, {
      injector
    });
    const unsubscribe = observer.subscribe(notifyManager.batchCalls(state => {
      ngZone.run(() => {
        if (state.isError && !state.isFetching &&
        // !isRestoring() && // todo: enable when client persistence is implemented
        shouldThrowError(observer.options.throwOnError, [state.error, observer.getCurrentQuery()])) {
          throw state.error;
        }
        resultSignal.set(state);
      });
    }));
    destroyRef.onDestroy(unsubscribe);
    return signalProxy(resultSignal);
  });
}

// src/util/assert-injector/assert-injector.ts
import { Injector as Injector2, assertInInjectionContext, inject as inject2, runInInjectionContext as runInInjectionContext2 } from "@angular/core";
function assertInjector(fn, injector, runner) {
  !injector && assertInInjectionContext(fn);
  const assertedInjector = injector ?? inject2(Injector2);
  if (!runner) return assertedInjector;
  return runInInjectionContext2(assertedInjector, runner);
}

// src/inject-infinite-query.ts
function injectInfiniteQuery(optionsFn, injector) {
  return assertInjector(injectInfiniteQuery, injector, () => createBaseQuery(optionsFn, InfiniteQueryObserver));
}

// src/inject-is-fetching.ts
import { DestroyRef as DestroyRef2, NgZone as NgZone2, inject as inject3, signal as signal2 } from "@angular/core";
import { QueryClient as QueryClient2, notifyManager as notifyManager2 } from "@tanstack/query-core";
function injectIsFetching(filters, injector) {
  return assertInjector(injectIsFetching, injector, () => {
    const destroyRef = inject3(DestroyRef2);
    const ngZone = inject3(NgZone2);
    const queryClient = inject3(QueryClient2);
    const cache = queryClient.getQueryCache();
    let isFetching = queryClient.isFetching(filters);
    const result = signal2(isFetching);
    const unsubscribe = cache.subscribe(notifyManager2.batchCalls(() => {
      const newIsFetching = queryClient.isFetching(filters);
      if (isFetching !== newIsFetching) {
        isFetching = newIsFetching;
        ngZone.run(() => {
          result.set(isFetching);
        });
      }
    }));
    destroyRef.onDestroy(unsubscribe);
    return result;
  });
}

// src/inject-is-mutating.ts
import { DestroyRef as DestroyRef3, NgZone as NgZone3, inject as inject4, signal as signal3 } from "@angular/core";
import { QueryClient as QueryClient3, notifyManager as notifyManager3 } from "@tanstack/query-core";
function injectIsMutating(filters, injector) {
  return assertInjector(injectIsMutating, injector, () => {
    const destroyRef = inject4(DestroyRef3);
    const ngZone = inject4(NgZone3);
    const queryClient = inject4(QueryClient3);
    const cache = queryClient.getMutationCache();
    let isMutating = queryClient.isMutating(filters);
    const result = signal3(isMutating);
    const unsubscribe = cache.subscribe(notifyManager3.batchCalls(() => {
      const newIsMutating = queryClient.isMutating(filters);
      if (isMutating !== newIsMutating) {
        isMutating = newIsMutating;
        ngZone.run(() => {
          result.set(isMutating);
        });
      }
    }));
    destroyRef.onDestroy(unsubscribe);
    return result;
  });
}

// src/inject-mutation.ts
import { DestroyRef as DestroyRef4, Injector as Injector3, NgZone as NgZone4, computed as computed3, effect as effect2, inject as inject5, runInInjectionContext as runInInjectionContext3, signal as signal4 } from "@angular/core";
import { MutationObserver, QueryClient as QueryClient4, notifyManager as notifyManager4 } from "@tanstack/query-core";
function injectMutation(optionsFn, injector) {
  return assertInjector(injectMutation, injector, () => {
    const currentInjector = inject5(Injector3);
    const destroyRef = inject5(DestroyRef4);
    const ngZone = inject5(NgZone4);
    const queryClient = inject5(QueryClient4);
    return lazyInit(() => runInInjectionContext3(currentInjector, () => {
      const observer = new MutationObserver(queryClient, optionsFn(queryClient));
      const mutate = (variables, mutateOptions) => {
        observer.mutate(variables, mutateOptions).catch(noop);
      };
      effect2(() => {
        observer.setOptions(runInInjectionContext3(currentInjector, () => optionsFn(queryClient)));
      });
      const result = signal4(observer.getCurrentResult());
      const unsubscribe = observer.subscribe(notifyManager4.batchCalls(state => {
        ngZone.run(() => {
          if (state.isError && shouldThrowError(observer.options.throwOnError, [state.error])) {
            throw state.error;
          }
          result.set(state);
        });
      }));
      destroyRef.onDestroy(unsubscribe);
      const resultSignal = computed3(() => ({
        ...result(),
        mutate,
        mutateAsync: result().mutate
      }));
      return signalProxy(resultSignal);
    }));
  });
}

// src/inject-mutation-state.ts
import { DestroyRef as DestroyRef5, NgZone as NgZone5, effect as effect3, inject as inject7, signal as signal5, untracked as untracked5 } from "@angular/core";
import { QueryClient as QueryClient5, notifyManager as notifyManager5, replaceEqualDeep } from "@tanstack/query-core";

// src/util/lazy-signal-initializer/lazy-signal-initializer.ts
import { Injector as Injector4, computed as computed4, inject as inject6, untracked as untracked4 } from "@angular/core";
function lazySignalInitializer(initializerFn) {
  const injector = inject6(Injector4);
  let source = null;
  const unwrapSignal = () => {
    if (!source) {
      source = untracked4(() => initializerFn(injector));
    }
    return source();
  };
  queueMicrotask(() => unwrapSignal());
  return computed4(unwrapSignal);
}

// src/inject-mutation-state.ts
function getResult(mutationCache, options) {
  return mutationCache.findAll(options.filters).map(mutation => options.select ? options.select(mutation) : mutation.state);
}
function injectMutationState(mutationStateOptionsFn = () => ({}), options) {
  return assertInjector(injectMutationState, options?.injector, () => {
    const destroyRef = inject7(DestroyRef5);
    const ngZone = inject7(NgZone5);
    const queryClient = inject7(QueryClient5);
    const mutationCache = queryClient.getMutationCache();
    return lazySignalInitializer(injector => {
      const result = signal5(getResult(mutationCache, mutationStateOptionsFn()));
      effect3(() => {
        const mutationStateOptions = mutationStateOptionsFn();
        untracked5(() => {
          result.set(getResult(mutationCache, mutationStateOptions));
        });
      }, {
        injector
      });
      const unsubscribe = mutationCache.subscribe(notifyManager5.batchCalls(() => {
        const nextResult = replaceEqualDeep(result(), getResult(mutationCache, mutationStateOptionsFn()));
        if (result() !== nextResult) {
          ngZone.run(() => {
            result.set(nextResult);
          });
        }
      }));
      destroyRef.onDestroy(unsubscribe);
      return result;
    });
  });
}

// src/inject-queries.ts
import { QueriesObserver, QueryClient as QueryClient6, notifyManager as notifyManager6 } from "@tanstack/query-core";
import { DestroyRef as DestroyRef6, computed as computed5, effect as effect4, inject as inject8, signal as signal6 } from "@angular/core";
function injectQueries({
  queries,
  ...options
}, injector) {
  return assertInjector(injectQueries, injector, () => {
    const queryClient = inject8(QueryClient6);
    const destroyRef = inject8(DestroyRef6);
    const defaultedQueries = computed5(() => {
      return queries().map(opts => {
        const defaultedOptions = queryClient.defaultQueryOptions(opts);
        defaultedOptions._optimisticResults = "optimistic";
        return defaultedOptions;
      });
    });
    const observer = new QueriesObserver(queryClient, defaultedQueries(), options);
    effect4(() => {
      observer.setQueries(defaultedQueries(), options, {
        listeners: false
      });
    });
    const [, getCombinedResult] = observer.getOptimisticResult(defaultedQueries(), options.combine);
    const result = signal6(getCombinedResult());
    const unsubscribe = observer.subscribe(notifyManager6.batchCalls(result.set));
    destroyRef.onDestroy(unsubscribe);
    return result;
  });
}

// src/inject-query.ts
import { QueryObserver } from "@tanstack/query-core";
function injectQuery(optionsFn, injector) {
  return assertInjector(injectQuery, injector, () => createBaseQuery(optionsFn, QueryObserver));
}

// src/inject-query-client.ts
import { Injector as Injector5, inject as inject9 } from "@angular/core";
import { QueryClient as QueryClient7 } from "@tanstack/query-core";
function injectQueryClient(injectOptions = {}) {
  return (injectOptions.injector ?? inject9(Injector5)).get(QueryClient7);
}

// src/providers.ts
import { DestroyRef as DestroyRef7, ENVIRONMENT_INITIALIZER, Injector as Injector6, PLATFORM_ID, computed as computed6, effect as effect5, inject as inject10, makeEnvironmentProviders, runInInjectionContext as runInInjectionContext4 } from "@angular/core";
import { QueryClient as QueryClient8, onlineManager } from "@tanstack/query-core";
import { isPlatformBrowser } from "@angular/common";

// src/util/is-dev-mode/is-dev-mode.ts
import { isDevMode } from "@angular/core";

// src/providers.ts
function provideQueryClient(queryClient) {
  return {
    provide: QueryClient8,
    useValue: queryClient
  };
}
function provideTanStackQuery(queryClient, ...features) {
  return makeEnvironmentProviders([provideQueryClient(queryClient), {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useValue: () => {
      queryClient.mount();
      inject10(DestroyRef7).onDestroy(() => queryClient.unmount());
    }
  }, features.map(feature => feature.ɵproviders)]);
}
function provideAngularQuery(queryClient) {
  return provideTanStackQuery(queryClient);
}
function queryFeature(kind, providers) {
  return {
    ɵkind: kind,
    ɵproviders: providers
  };
}
function withDevtools(optionsFn) {
  let providers = [];
  if (!isDevMode() && !optionsFn) {
    providers = [];
  } else {
    providers = [{
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useFactory: () => {
        if (!isPlatformBrowser(inject10(PLATFORM_ID))) return () => {};
        const injector = inject10(Injector6);
        const options = computed6(() => runInInjectionContext4(injector, () => optionsFn?.() ?? {}));
        let devtools = null;
        let el = null;
        const shouldLoadToolsSignal = computed6(() => {
          const {
            loadDevtools
          } = options();
          return typeof loadDevtools === "boolean" ? loadDevtools : isDevMode();
        });
        const destroyRef = inject10(DestroyRef7);
        const getResolvedQueryClient = () => {
          const injectedClient = injector.get(QueryClient8, null);
          const client = options().client ?? injectedClient;
          if (!client) {
            throw new Error("No QueryClient found");
          }
          return client;
        };
        const destroyDevtools = () => {
          devtools?.unmount();
          el?.remove();
          devtools = null;
        };
        return () => effect5(() => {
          const shouldLoadTools = shouldLoadToolsSignal();
          const {
            client,
            position,
            errorTypes,
            buttonPosition,
            initialIsOpen
          } = options();
          if (devtools && !shouldLoadTools) {
            destroyDevtools();
            return;
          } else if (devtools && shouldLoadTools) {
            client && devtools.setClient(client);
            position && devtools.setPosition(position);
            errorTypes && devtools.setErrorTypes(errorTypes);
            buttonPosition && devtools.setButtonPosition(buttonPosition);
            initialIsOpen && devtools.setInitialIsOpen(initialIsOpen);
            return;
          } else if (!shouldLoadTools) {
            return;
          }
          el = document.body.appendChild(document.createElement("div"));
          el.classList.add("tsqd-parent-container");
          import("@tanstack/query-devtools").then(queryDevtools => runInInjectionContext4(injector, () => {
            devtools = new queryDevtools.TanstackQueryDevtools({
              ...options(),
              client: getResolvedQueryClient(),
              queryFlavor: "Angular Query",
              version: "5",
              onlineManager
            });
            el && devtools.mount(el);
            destroyRef.onDestroy(destroyDevtools);
          }));
        });
      }
    }];
  }
  return queryFeature("DeveloperTools", providers);
}
var queryFeatures = ["DeveloperTools"];
export { infiniteQueryOptions, injectInfiniteQuery, injectIsFetching, injectIsMutating, injectMutation, injectMutationState, injectQueries, injectQuery, injectQueryClient, provideAngularQuery, provideQueryClient, provideTanStackQuery, queryFeatures, queryOptions, withDevtools };
