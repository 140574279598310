/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */

export type OperatingMode = typeof OperatingMode[keyof typeof OperatingMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperatingMode = {
  ON: 'ON',
  ONREG: 'ONREG',
  ONTEST: 'ONTEST',
  OUT: 'OUT',
  ONCLR: 'ONCLR',
  ONRGL: 'ONRGL',
  OUTL: 'OUTL',
  ONRUC: 'ONRUC',
  ONOS: 'ONOS',
  ONOSREG: 'ONOSREG',
  ONDSRREG: 'ONDSRREG',
  ONDSR: 'ONDSR',
  OFF: 'OFF',
  OFFNS: 'OFFNS',
  ONEMR: 'ONEMR',
  ONRR: 'ONRR',
  EMR: 'EMR',
  ONRL: 'ONRL',
  ONOPTOUT: 'ONOPTOUT',
  OFFQS: 'OFFQS',
  EMRSWGR: 'EMRSWGR',
} as const;
