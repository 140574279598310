import { SimpleChange } from '@angular/core';
import colorLib from '@kurkle/color';
import { RemoteApiException } from '@models/api';
import _ from 'lodash';
import moment from 'moment';
import { uiTimezone } from './components/voltaire/constants';

/**
 * Returns a union of all of the keys in the specified object type
 * T who are of the specified type V.
 * 
 * Usage: For optional types, use `Required<T>` when calling this function.
 */
export type KeyOfType<T,V> = keyof {
    [K in keyof T as Required<T>[K] extends V ? K : never]: T[K];
}


/**
 * Utility function to make every property required, recursively
 */
export type RequiredDeep<T> = {
    [P in keyof T]-?: T[P] extends object ? RequiredDeep<T[P]> : Required<T[P]>;
};

export function range(startAt:number, endAt:number): number[]{
    return Array.from(Array((endAt-startAt) + 1).keys()).map( i => i + startAt);
}

/**
 * Strongly typed version of Object.entries() 
 * @param input 
 * @returns 
 */
export function objectEntries<T extends object>(input:T): [keyof T, T[keyof T]][]{
    return Object.entries(input) as [keyof T, T[keyof T]][];
}

/**
 * More type safe alternate to lodash `_.keyBy` function
 * @param collection 
 * @param key 
 * @returns 
 */
export function keyBy<TData extends Record<string,any>>(collection:TData[], key:keyof TData):Record<typeof key, TData>{
    return collection.reduce((a, c) => ({ ...a, [c[key]]: c }), <Record<typeof key, TData>>{}); 
}

/**
 * Similar to _.keyBy but specify the output value instead of mapping the whole object.
 * Supports `getKey()` callback to customize target key.
 * 
 * @param collection 
 * @param key 
 * @param value 
 * @param getKey Allows custom creation of key (used for date / time rollover)
 * @returns 
 */
export function keyValueBy<T extends {[key:string]:any}, K extends keyof T, V extends keyof T>
    (collection : T[], key : K, value: V, getKey?: {(prev:any,cur:any):any} ): {[key in T[K]]:T[V] } {
    
        let lastKey : any = null;

    return collection.reduce((prev, cur) => {
        let k = cur[key];
        if(typeof getKey == 'function'){
            k = getKey(lastKey, k);
        }
        prev[k] = cur[value];
        
        lastKey = k;
        return prev;
    }, <any>{});
}

/**
 * Update the collection with the item that has `id` matching that passed in 
 * else add said item to the start of the collection
 * @param collection 
 * @param item 
 */
export function upsert(collection : {id?:string}[], item : {id?:string}){
    let index = collection.findIndex( i => i.id == item.id );
    if(index > -1){
        collection[index] = item;
    }
    else{
        collection.unshift(item);
    }
}

/**
 * Calculate days offset from present date and time
 * @param daysFuture 
 * @param d 
 * @returns 
 */
export function daysOffset(daysFuture : number = 0, d : Date = new Date()){
    let dayMs = 60 * 60 * 24 * 1000;
    return new Date(d.getTime() + dayMs * daysFuture);
}

export class FormatDate{
    static YMD(d : Date | string){
       if(!(d instanceof Date)){
        d = new Date(d);
       }
       return `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
    }
    /**
     * Format the passed date or string in the UI display timezone (usually Central Time) with the passed moment date format string
     * @param d 
     * @param format 
     * @returns 
     */
    static UITimezone(d:Date|string, format: string):string{
        return moment(d).tz(uiTimezone).format(format);
    }
    /**
     * Convert the passed date to Central time and get the start of that day.
     */
    static StartOfDayCentral(d:Date):moment.Moment{
        return moment(d).tz(uiTimezone).startOf('day');
    }
    /**
     * Creates a new date object for midnight in the browser locale with the date of the passed string
     * equivalent in Central time.
     * Used to populate the date widget from a Central date string
     * @param d 
     * @returns 
     */
    static StartOfDayLocal(d:Date|string){
        let m = moment(d).tz(uiTimezone);
        return new Date(m.year(), m.month(), m.date());
    }
}

export function transparentize(value:any, opacity:number) {
    var alpha = opacity === undefined ? 0.5 : 1 - opacity;
    return colorLib(value).alpha(alpha).rgbString();
}

/**
 * Get the present hour ending in Central time, used to determine final data point to fill
 * on charts.
 */
export function hourEndingCT(){
    let dateInCT = new Date().toLocaleString('en-US', {
        timeZone: uiTimezone,
        hour12: false
    })
    return new Date(dateInCT).getHours() + 1;
}

/**
 * Interprets the date and time of the passed date object as though Central time.
 * @param value 
 */
export function asCTDate(value:Date):Date{
    const formatStr = 'YYYY-MM-DD HH:mm.ss';
    const inputDateOnly = moment(value).format(formatStr);
    return moment.tz(inputDateOnly, formatStr, uiTimezone).toDate();
}

/**
 * Return an object consisting of keys of the original for which the value is not empty.
 * If the value is an object, call this recursively, else use simply `_.isEmpty()` call.
 */
export function filterDeep<T>(input:T):Partial<T> | undefined{
    if(_.isArray(input)){
        return _.isEmpty(input) ? undefined : input;
    }
    if(!_.isObject(input)){
        return input;
    }
    let res = Object.entries(input).reduce((p:any,[k,v]) => {
        let val = filterDeep(v);
        if(!_.isUndefined(val) && !_.isNull(val)){
            p[k] = val;
        }
        return p;
    }, {});
    return _.isEmpty(res) ? undefined : res;
}

/**
 * Extract the parts of an observable error and return a summary and detail string to be used in an NGPrime message
 * 
 * @description Accepted object types:
 * 
 * `RemoteApiException` will wrap the `remoteResponse` property in `<pre class="scrollable"></pre>` to allow contained
 * rendering of whatever payload the gateway returned.
 * 
 * In C# just return a `RemoteApiException` as the argument to `BadRequest` or `StatusCode(500, [object])`
 * 
 * @param data 
 * @returns 
 */
export function extractErrorMessage(data : any ) : {summary:string, detail?:string}{
    
    const toHTML = (keyValues : Record<string,string>) => {
        return `<ul>${Object.entries(keyValues).filter(([k,v]) => v != undefined).map( ([k,v]) => `<li><strong>${k}:</strong> ${v}</li>`).join('\n')}</ul>`;
    }

    let summary = 'Error making request';

    // Override subject if we have short description of error
    if(_.isString(data.message)){
        summary = data.message;
    }

    let detail = undefined;

    if(data.status == 403){
        detail = "You do not have the correct permissions to perform this operation.";
    }
    
    if(_.isString(data)){
        return {summary, detail: data};
    }
    
    else if(_.isObject(data.error)){

        if(data.error instanceof ProgressEvent){
            summary =  'Network error (could not connect)';
            detail = data.message;
        }

        else if(data.error.className == 'RemoteApiException'){

            const err = data.error as RemoteApiException;

            return {
                summary: 'Remote API Exception',
                detail: toHTML({
                    API : err.remoteUrl,
                    StatusCode: String(err.remoteStatusCode),
                    Details : `<pre class='scrollable'>${err.remoteResponse}</pre>`
                })
            }
        }
        // Validation errors returned by C# MVC framework
        else if(_.isObject(data.error?.errors)){
            let validations = <Record<string,string[]>>(data.error.errors);
            return {
                summary: 'Model validation errors (incorrect payload)',
                detail: `<ol>${Object.entries(validations).map( ([k,v]) => `<li><strong>${k}:</strong> ${v.join(' ')}</li>`).join('\n')}</ol>`
            }
        }
        else if(data.error.message || data.error.Message){
            detail =  data.error.message || data.error.Message;
        }
        else if(data.error.detail){
            detail = data.error.detail;
        }
        if(data.error.title){
            summary = data.error.title;
        }
    }

    else if(_.isString(data.error)){
        detail = data.error;
    }
    

    if(_.isString(detail)){
        if(detail.indexOf('\n') >= 0){
            detail = `<pre class='scrollable'>${detail}</pre>`;
        }
    }
   
    return {summary, detail};
}
/** Creates a unique Identifier */
export function generateGUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * Alternate to @core/angular SimpleChanges type which is type safe
 * Use with `typeof this` of simply `MyComponentName` in the `ngOnChanges()` callback
 */
export type SimpleChanges<T> = {[propName in keyof T]:SimpleChange};
