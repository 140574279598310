import { NgModule } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from "primeng/dropdown";
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldsetModule } from 'primeng/fieldset';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ListboxModule } from 'primeng/listbox';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SkeletonModule } from 'primeng/skeleton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
    exports: [
        BadgeModule,
        BreadcrumbModule,
        CalendarModule,
        CardModule,
        CheckboxModule, 
        ConfirmDialogModule,
        ConfirmPopupModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FloatLabelModule,
        InputGroupModule,
        InputNumberModule,
        InputTextModule,
        InputTextareaModule,
        InputSwitchModule, 
        ListboxModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        PanelModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        SkeletonModule,
        SplitButtonModule,
        TabViewModule,
        TableModule,
        TagModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule
    ]
})
export class PrimeNgModule { }