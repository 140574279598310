{
  "name": "power",
  "version": "1.3.3",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "serve": "ng build --configuration development&&serve ./dist/power -l tcp://0.0.0.0:8080 -s"
  },
  "private": true,
  "dependencies": {
    "-": "^0.0.1",
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.13",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/material": "^18.2.13",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@azure/msal-angular": "^3.1.0",
    "@tanstack/angular-query-devtools-experimental": "^5.60.5",
    "@tanstack/angular-query-experimental": "^5.52.2",
    "chart.js": "^4.4.6",
    "chartjs-adapter-moment": "^1.0.1",
    "chartjs-plugin-annotation": "^3.1.0",
    "chartjs-plugin-zoom": "^2.1.0",
    "chroma-js": "^3.1.2",
    "date-fns": "^4.1.0",
    "lodash": "^4.17.21",
    "moment": "^2.29.4",
    "moment-timezone": "^0.5.45",
    "ngx-highlightjs": "^12.0.0",
    "ngx-markdown": "^18.1.0",
    "ngx-moment": "^6.0.2",
    "primeflex": "^3.3.1",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.11",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "url-join": "^5.0.0",
    "xml-formatter": "^3.6.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@types/chroma-js": "^2.4.4",
    "@types/jasmine": "^5.1.4",
    "@types/lodash": "^4.17.13",
    "jasmine-core": "^5.4.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "^3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "orval": "^7.1.0",
    "serve": "^14.2.3",
    "typescript": "~5.4.2"
  }
}
