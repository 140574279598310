import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, Signal, signal } from '@angular/core';
import { Observable, Subject, filter, firstValueFrom, takeUntil, throwError } from 'rxjs';

import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';

import { GraphAccountInfo } from '@models/api';
import { protectedResources } from 'src/app/auth/config';
import { environment } from 'src/environments/environment';
import { ModulesResponse, PlayBookRes } from 'src/models/playbook/playbook';
import { FormatDate } from '../utils';

import { BacktestService } from '@models/api/orval/interfaces/backtest';
import { GeneralService } from '@models/api/orval/interfaces/general';
import { OptimizerService } from '@models/api/orval/interfaces/optimizer';
import {
  AncillarySubmissionDocument,
  AppConfig, AppSettings,
  BacktestRequest,
  CopSubmissionDocument, DeleteApiBacktestBacktestParams, DeleteApiBacktestStrategyParams,
  EnergySubmissionDocument,
  GetApiTransactionsAncillariesParams,
  IpAddress,
  PostApiDatabricksUsersDeleteParams, PostApiOptimizerGenerateTypeTargetDateParams,
  QueryAsset,
  QueryTarget,
  Strategy, StrategyDeltaRequest, StrategyPlotRequest,
  SubmissionRequest, SubmissionType, TargetDate
} from '@models/api/orval/schemas';

import { DataBricksService } from '@models/api/orval/interfaces/data-bricks';
import { ForecastService } from '@models/api/orval/interfaces/forecast';
import { ProxyService } from '@models/api/orval/interfaces/proxy';
import { TenaskaService } from '@models/api/orval/interfaces/tenaska';
import { TransactionsService } from '@models/api/orval/interfaces/transactions';
import { CreateQueryResult, QueryClient, injectMutation, injectQuery, injectQueryClient } from '@tanstack/angular-query-experimental';
import { OverrideCache } from './overrideCache';


import { PlayBookService } from '@models/api/orval/interfaces/play-book';


/**
 * API Service provides access to .NET backend services
 * 
 * @description
 * Each backend controller (`api/backtests`) should be mapped to a different namespace (i.e. `apiService.backtest.get()`)
 * 
 * Events:
 * {@link ApiService#beforeAction} fires before a HTTP POST or GET action is performed and can be used to clear prior error messages.
 * 
 * Key sections:
 * {@link ApiService#tenaska} - Proxied and formatted responses from Tenaska
 * 
 * 
 * Be sure to unsubscribe from actions in the consumer's destructor to avoid memory leaks.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService implements OnDestroy {

  get loggedIn() {
    return this.activeAccount != undefined;
  }
  /**
   * Contains the MSAL account info of the logged in user
   */
  activeAccount?: AccountInfo;

  /**
   * The /graph/me user profile fetched as separate transaction after validating user account
   */
  activeProfile?: GraphAccountInfo;

  overrides = new OverrideCache(this.transactionService);

  private queryClient = injectQueryClient();

  private _beforeAction$ = new Subject<'post' | 'get' | 'delete'>();

  public beforeAction = this._beforeAction$.asObservable();

  private readonly _destroying$ = new Subject<void>();

  /**
   * Signal which will contain the app configuration when loaded
   * Use this instead of injecting separate app configuration queries into components
   */
  readonly appConfig!: CreateQueryResult<AppConfig>;

  constructor(
    private forecastService: ForecastService,
    private generalService: GeneralService,
    private backtestService: BacktestService,
    private optimizerService: OptimizerService,
    private playbookService: PlayBookService,
    private proxyService: ProxyService,
    private tenaskaService: TenaskaService,
    private transactionService: TransactionsService,
    private databricksService: DataBricksService,
    private httpClient: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService) {
    this.initialize();

    this.appConfig = this.config.getQuery();
  }

  /**
   * Initialize MSAL and fetch server settings
   */
  private async initialize() {
    this.initMsal();

    try {
      this.overrides.getAsOverrides();
    }
    catch (e) {
      alert(`Error fetching overrides (see console)`);
      console.error(`Error fetching overrides`, e);
    }
  }

  /**
   * Setup listeners to update class state properties ({ApiService.loggedIn}) in response MSAL events
   */
  private initMsal() {
    this.msalService.instance.enableAccountStorageEvents();

    // This event fires more reliably than the broadcast service events in Chrome
    this.msalService.handleRedirectObservable().subscribe({
      next: (res) => this.checkAndSetActiveAccount(),
      error: (err) => console.error(`MSAL handleRedirectObservable error`, err)
    })

    // Enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.checkAndSetActiveAccount();
        }
      });

    // Several events will be emitted (startup, redirect).  The None event is the steady state where 
    // we should have an active user instance and be able to query authorized end points.
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((value: InteractionStatus) => {
        this.checkAndSetActiveAccount();
      });
  }

  /**
   * Query, set class property and log active account in response to MSAL login event
   */
  private async checkAndSetActiveAccount() {

    if (this.activeAccount) {
      console.warn(`checkAndSetActiveAccount: already set, returning`);
      return;
    }

    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      // Default to first logged into account 
      activeAccount = accounts[0];
      this.msalService.instance.setActiveAccount(activeAccount);
    }

    if (activeAccount) {
      this.activeAccount = activeAccount;
      this.activeProfile = await firstValueFrom(this.httpClient.get<GraphAccountInfo>(protectedResources.msGraphMe.endpoint));
    }
  }

  /**
   * Login user via redirect method
   */
  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect();
    }
  }

  /**
   * Logout user via redirect method
   */
  logoutRedirect() {
    this.msalService.logoutRedirect();
  }

  /**
   * Promisified version of `HttpClient.post` that prefixes appropriate backend server to URI.
   * Function signature matches that of `HttpClient.post()`
   */
  async post<T>(url: string, payload: any, options?: Parameters<typeof this.httpClient.post>[2]): Promise<T> {
    this._beforeAction$.next('post');
    return firstValueFrom(this.httpClient.post<T>(`${environment.baseHref}/${url}`, payload, options));
  }
  async get<T>(url: string, options?: Parameters<typeof this.httpClient.post>[1]): Promise<T> {
    this._beforeAction$.next('get');
    return firstValueFrom(<Observable<T>>this.httpClient.get<T>(`${environment.baseHref}/${url}`, options));
  }
  async delete(url: string, options?: Parameters<typeof this.httpClient.post>[1]): Promise<any> {
    this._beforeAction$.next('delete');
    return firstValueFrom(<Observable<any>>this.httpClient.delete(`${environment.baseHref}/${url}`, options));
  }

  getPlayBookData(): Observable<PlayBookRes> {
    return this.playbookService.getApiPlaybookData();
  }
  getModules(): Observable<ModulesResponse> {
    return this.playbookService.getApiPlaybookModules();
  }
  getDataFromCosmos(container: any): Observable<any> {
    return this.playbookService.postApiPlaybookGetCosmos(container);
  }
  postScenarioData(data: any): Observable<any> {
    return this.playbookService.postApiPlaybookScenario(data);
  }

  tenaska = {
    /** Get Tenaska ancillary awards for the date in question */
    awards: (date: Signal<string>, assets: QueryTarget[]) => injectQuery(() => ({
      queryKey: ['tenaskaAwards', { date: date(), assets }],
      queryFn: () => firstValueFrom(this.tenaskaService.postApiTenaskaAwards({ startDate: date(), assets }))
    })),
    /**
     * Inject Tenaska availability query for both 
     * @param date Should be in format YYYY-MM-DD
     */
    availability: (date: Signal<string>, assets: QueryTarget[], enabled: Signal<boolean> = signal(true)) => injectQuery(() => ({
      queryKey: ['tenaskaAvailability', { date: date(), assets }],
      queryFn: () => {
        return firstValueFrom(this.tenaskaService.postApiTenaskaAvailability({ assets, startDate: date() }));
      },
      enabled: enabled()
    })),
  }

  ignition = {
    batteryHealth: (date: Signal<Date>) => injectQuery(() => ({
      queryKey: ['ignitionBatteryHealth', FormatDate.YMD(date())],
      queryFn: () => firstValueFrom(this.proxyService.getApiProxyIgnitionBatteryHealth({ date: FormatDate.YMD(date()) }))
    })),
    batteryHealthHiRes: (date: Signal<Date>, asset: Signal<QueryAsset>) => injectQuery(() => ({
      queryKey: ['ignitionBatteryHealthHiRes', { date: FormatDate.YMD(date()), unit: asset() }],
      queryFn: () => firstValueFrom(this.proxyService.getApiProxyIgnitionBatteryHealthHiRes({ date: FormatDate.YMD(date()), asset: asset() }))
    }))
  }

  forecast = {
    solarBands: (date: Signal<Date>) => injectQuery(() => ({
      queryKey: ['solarBands', FormatDate.YMD(date())],
      queryFn: () => firstValueFrom(this.forecastService.getApiForecastSolarBands({ date: FormatDate.YMD(date()) }))
    })),
    /**
     * Get the payload for an Angular `injectQuery()` call which must be executed 
     * in the remote class context for signal tracking to work.
     */
    ancillaries: (date: Signal<Date>) => injectQuery(() => ({
      queryKey: ['voltaireAncillaryForecast', { date: FormatDate.YMD(date()) }],
      queryFn: () => firstValueFrom(this.forecastService.getApiForecastAncillaries({ startDate: FormatDate.YMD(date()) }))
    }))
  }

  /**
   * Listing and generating transactions
   */
  transactions = {
    latestAncillary: (date: Signal<Date>) => injectQuery(() => ({
      queryKey: ['latestAncillarySubmission', FormatDate.YMD(date())],
      queryFn: () => firstValueFrom(this.optimizerService.getApiOptimizerLatestAncillaries({ bidDate: FormatDate.YMD(date()) }))
    })),
    /**
     * Get query to fetch all transaction documents
     * @param params Signal which will re-trigger fetch when parameters change
     * @param enabled Signal used purely for the cache signature (does not affect whether transaction request actually made) 
     */
    getQuery: (params: Signal<GetApiTransactionsAncillariesParams | undefined>, enabled: Signal<boolean>) =>

      injectQuery<AncillarySubmissionDocument[] | CopSubmissionDocument[] | EnergySubmissionDocument[]>(() => ({
        queryKey: ['transactions', { ...params() }],
        queryFn: () => {
          let p = params();
          switch (p?.RequestType) {
            case 'ancillaries':
              return firstValueFrom(this.transactionService.getApiTransactionsAncillaries(p));
            case 'cop':
              return firstValueFrom(this.transactionService.getApiTransactionsCop(p));
            case 'realtime':
              return firstValueFrom(this.transactionService.getApiTransactionsRealtime(p));
            default:
              throw new Error(`Unknown request type: ${p?.RequestType}`);
          }
        },
        enabled: enabled() && !!params()
      })),

    /**
     * Mutation to submit a previously generated document
     */
    getSubmitMutation: () => injectMutation((client) => ({
      mutationFn: (data: SubmissionRequest) => firstValueFrom(this.optimizerService.postApiOptimizerSubmit(data)),

      onSuccess: (result, params: SubmissionRequest) => {
        let p: GetApiTransactionsAncillariesParams = { RequestType: params.submissionType };
        client.invalidateQueries({ queryKey: ['transactions', p] })
      }
    })),
    /** 
     * Generate a submission document automatically (using optimizer for AS and COP)
     */
    getGenerateMutation: () => injectMutation((client: QueryClient) => ({
      mutationFn: (data: { type: SubmissionType, target: TargetDate, params?: PostApiOptimizerGenerateTypeTargetDateParams }) =>
        firstValueFrom(this.optimizerService.postApiOptimizerGenerateTypeTargetDate(data.type, data.target, data.params)),

      onSuccess: (result, params) => {
        let p: GetApiTransactionsAncillariesParams = { RequestType: params.type };
        client.invalidateQueries({ queryKey: ['transactions', p] });
      }
    }))
  }

  /**
   *  All RPCs relating to the backtest service
   */
  backtest = {
    strategies: {
      getAllQuery: () => injectQuery(() => ({
        queryKey: ['strategies'],
        queryFn: () => firstValueFrom(this.backtestService.getApiBacktestStrategies())
      })),
      getUpdateMutation: () => injectMutation((client: QueryClient) => ({
        mutationFn: (val: Strategy) => firstValueFrom(this.backtestService.postApiBacktestStrategy(val)),
        onSuccess: () => client.invalidateQueries({ queryKey: ['strategies'] })
      })),
      getDeleteMutation: () => injectMutation((client: QueryClient) => ({
        mutationFn: (params: DeleteApiBacktestStrategyParams) => firstValueFrom(this.backtestService.deleteApiBacktestStrategy(params)),
        onSuccess: () => client.invalidateQueries({ queryKey: ['strategies'] })
      }))
    },
    getQuery: () => injectQuery(() => ({
      queryKey: ['backtests'],
      queryFn: () => firstValueFrom(this.backtestService.getApiBacktestAll())
    })),
    /** Mutation to submit new backtest */
    getSubmitMutation: () => injectMutation((client: QueryClient) => ({
      mutationFn: (data: BacktestRequest) => firstValueFrom(this.backtestService.postApiBacktestSubmit(data)),
      onSuccess: () => client.invalidateQueries({ queryKey: ['backtests'] })
    })),
    /** Mutation to get / advance status of existing backtest */
    getStatusMutation: () => injectMutation((client: QueryClient) => ({
      mutationFn: (...args: Parameters<BacktestService['postApiBacktestQuery']>) => firstValueFrom(this.backtestService.postApiBacktestQuery(...args)),
      onSuccess: () => client.invalidateQueries({ queryKey: ['backtests'] })
    })),
    /** Get mutation which deletes specified backtest */
    getDeleteMutation: () => injectMutation((client: QueryClient) => ({
      mutationFn: (params: DeleteApiBacktestBacktestParams) => firstValueFrom(this.backtestService.deleteApiBacktestBacktest(params)),
      onSuccess: () => client.invalidateQueries({ queryKey: ['backtests'] })
    })),
    getStrategyData: (request: StrategyPlotRequest) => firstValueFrom(this.backtestService.postApiBacktestStrategiesHourly(request)),
    getStrategyDelta: (request: StrategyDeltaRequest) => firstValueFrom(this.backtestService.postApiBacktestStrategiesDelta(request))
  };
  config = {
    getQuery: () => injectQuery(() => ({
      queryKey: ['appConfig'],
      queryFn: () => firstValueFrom(this.generalService.getApiGeneralConfig()),
    })),
    getData: () => this.queryClient.fetchQuery<AppConfig>({
      queryKey: ['appConfig'],
      queryFn: () => firstValueFrom(this.generalService.getApiGeneralConfig()),
    })
  };
  /**RPCs to call Databricks */
  databricks = {
    getUsers: () => injectQuery(() => ({
      queryKey: ['databricksUsers '],
      queryFn: () => firstValueFrom(this.databricksService.getApiDatabricksUsersGet()),
    })),
    save: () => injectMutation((client) => ({
      mutationFn: (data: IpAddress) => firstValueFrom(this.databricksService.postApiDatabricksUsersChange(data)),
      onSuccess: () => client.invalidateQueries({ queryKey: ['databricksUsers'] })
    })),
    delete: () => injectMutation((client) => ({
      mutationFn: (data: PostApiDatabricksUsersDeleteParams) => firstValueFrom(this.databricksService.postApiDatabricksUsersDelete(data)),
      onSuccess: () => client.invalidateQueries({ queryKey: ['databricksUsers'] })
    })),

  };
  /**
   * Angular query objects to fetch and mutate the app settings
   */
  settings = {
    getQuery: () => injectQuery(() => ({
      queryKey: ['appSettings'],
      queryFn: () => firstValueFrom(this.generalService.getApiGeneralSettings()),
    })),
    getMutation: () => injectMutation((client) => ({
      mutationFn: (data: AppSettings) => firstValueFrom(this.generalService.postApiGeneralSettings(data)),
      onSuccess: () => client.invalidateQueries({ queryKey: ['appSettings'] })
    }))
  }

  private handleError(err: any) {
    console.error(err);
    console.error(err.error);
    return throwError(err.error);
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
