/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  AppConfig,
  AppSettings
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class GeneralService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * @summary Non-changeable settings that are fixed in config files or because they're constants
 */
 getApiGeneralConfig<TData = AppConfig>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/general/config`,options
    );
  }
/**
 * @summary User changeable configuration settings which are persisted in Cosmos
 */
 getApiGeneralSettings<TData = AppSettings>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/general/settings`,options
    );
  }
 postApiGeneralSettings<TData = AppSettings>(
    appSettings: AppSettings, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/general/settings`,
      appSettings,options
    );
  }
};

export type GetApiGeneralConfigClientResult = NonNullable<AppConfig>
export type GetApiGeneralSettingsClientResult = NonNullable<AppSettings>
export type PostApiGeneralSettingsClientResult = NonNullable<AppSettings>
